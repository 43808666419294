exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-contributors-js": () => import("./../../../src/pages/contributors.js" /* webpackChunkName: "component---src-pages-contributors-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-toolbox-js": () => import("./../../../src/pages/toolbox.js" /* webpackChunkName: "component---src-pages-toolbox-js" */),
  "component---src-pages-tournaments-js": () => import("./../../../src/pages/tournaments.js" /* webpackChunkName: "component---src-pages-tournaments-js" */),
  "component---src-templates-article-details-js": () => import("./../../../src/templates/article-details.js" /* webpackChunkName: "component---src-templates-article-details-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-seasons-js": () => import("./../../../src/templates/seasons.js" /* webpackChunkName: "component---src-templates-seasons-js" */),
  "component---src-templates-tournament-details-js": () => import("./../../../src/templates/tournament-details.js" /* webpackChunkName: "component---src-templates-tournament-details-js" */)
}

